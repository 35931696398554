import { makeStyles } from '@material-ui/core';
import aboutUsBg from '../../../../images/element_apropos.svg';

export default makeStyles((theme) => ({
  aboutUsSection: {
    position: 'relative',
    backgroundColor: 'transparent',
  },
  aboutUsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: 1650,
  },
  aboutUs: {
    color: '#fff',
    position: 'relative',
    zIndex: 1,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(19),

    '@media (max-width: 1600px)': {
      paddingTop: theme.spacing(6.75),
      paddingBottom: theme.spacing(6.75),
    },
    '@media (max-width: 992px)': {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundImage: `url(${aboutUsBg})`,
    backgroundColor: '#333',
    backgroundPosition: '0 100%',
    backgroundRepeat: 'no-repeat',
    '@media (max-width: 1600px)': {
      backgroundPosition: '100% 0',
    },
    '@media (max-width: 768px)': {},
  },

  arrow: {
    position: 'absolute',
    top: 'calc(50% - 16px)',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF',
    zIndex: 10,
    borderRadius: '50%',
    boxShadow: '0 0 5px rgba(0,0,0,.16)',
    '&:first-of-type': {
      left: 10,
    },
    '&:last-of-type': {
      right: 10,
    },
  },
  aboutUsText: {
    maxWidth: 955,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign:'justify',
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    '@media (max-width: 1600px)': {
      paddingTop: theme.spacing(2),
    },
    '@media (max-width: 992px)': {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  aboutUsTitle: {
    '&:after': {
      backgroundColor: '#E0363A',
    },
  },

  aboutUsParagraphe: {
    fontSize: '1.25rem',
    marginBottom: theme.spacing(3),
    '@media (max-width: 768px)': {
      margin: '1.5rem 0',
    },
    '& span': {
      color: '#E0363A',
    },
  },

  aboutUsIllustrationWrapper: {
    maxWidth: 571,
    '& img': {
      width: '100%',
    },
    '@media (max-width: 1200px)': {
      maxWidth: '30%',
    },
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
}));
