import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  button: {
    padding: '8px 25px',
    marginTop: theme.spacing(4),
    '&:hover': {
      opacity: 0.75,
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {},
  },
  span: {
    textTransform: 'uppercase',
    fontWeight: 500,
  },
}));
