import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  teamSection: {},
  teamContainer: {
    maxWidth: 1750,
  },
  topBox: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  teamIllustrationWrapper: {
    maxWidth: 705,
    '& img': {
      width: '100%',
    },
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
  teamText: {
    maxWidth: 799,
    '@media (max-width: 1400px)': {
      maxWidth: 690,
    },
    '@media (max-width: 1200px)': {
      maxWidth: 550,
    },
    '@media (max-width: 992px)': {
      maxWidth: '100%',
    },
  },
  teamTitle: {
    fontFamily: 'ubuntu',
    letterSpacing: '-1.8px',
    '@media (min-width: 1401px)': {
      fontSize: '7.5rem !important',
      lineHeight: '138px !important',
    },
    '@media (min-width: 1200px) and (max-width: 1400px)': {
      fontSize: '6.5rem !important',
      lineHeight: '110px !important',
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
      fontSize: '5rem !important',
      lineHeight: '90px !important',
    },
  },
  teamParagraphe: {
    '@media (min-width: 1401px)': {
      fontSize: '1.725rem',
    },
    fontSize: '1.25rem',
    color: '#808080',
    fontWeight: 300,
  },
  rejoignezNous: {
    width: 'auto',
    maxWidth: 'fit-content',
    borderColor: '#272727',
    letterSpacing: 1.25,
    padding: '8px 22px',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  listingTeam: {
    flexWrap: 'wrap',
    justifyContent: 'center',   
  },
  card: {
    boxShadow: 'none',
    textAlign: 'center',
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    '@media (max-width: 1200px)': {
      minWidth: '50%',
    },
    '@media (max-width: 580px)': {
      width: '85%',
    },
  },
  cardHeader: {},
  media: {
    minHeight: 256,
    width: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center bottom',
  },
  name: {
    fontSize: '1.250rem',
    fontWeight: 500,
  },
  position: {
    color: '#808080',
    fontSize: '0.825rem',
  },
}));
