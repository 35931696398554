import { makeStyles } from '@material-ui/core';
import Bg from '../../../../images/element_nous_trouvez.svg';

export default makeStyles((theme) => ({
  OuNousTrouverSection: {
    textAlign: 'center',
    color: '#fff',
    position: 'relative',
  },
  background: {
    backgroundColor: '#333',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'inline-block',
    content: '""',
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundImage: `url(${Bg})`,
    backgroundPosition: '0 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    '@media (max-width: 992px)': {
      backgroundImage: 'inherit',
    },
  },
  OuNousTrouverContainer: {},
  contentWrapper: {
    paddingTop: 100,
    paddingBottom: 100,
    '@media (max-width: 1200px)': {
      paddingTop: 50,
      paddingBottom: 50,
    },
    '@media (max-width: 768px)': {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  title: {
    '&:after': {
      backgroundColor: '#E0363A',
      right: 0,
      margin: 'auto',
    },
  },
  description: {
    maxWidth: 775,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '1.50rem',
    '& span': {
      color: '#F1CA61',
    },
  },
  imageIllustrationWrapper: {
    marginTop: 90,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1205,
    '& img': {
      width: '100%',
    },
  },
}));
