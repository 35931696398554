import React from 'react';
import Container from '../components/Container';
import SEO from '../components/seo';
import APropos from '../components/PagesComponent/APropos/APropos';

function AProposPage() {
  return (
    <Container>
      <SEO
        title="Hairun Technology - A propos"
        description="HaiRun Technology est une ESN (Entreprise de Services du numérique) basée à Madagascar et représentée à Paris."
      />
      <APropos />
    </Container>
  );
}

export default AProposPage;
