import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Layout from '../../../Common/Layout';
import styles from './style';
import classnames from 'classnames';

import ReactHtmlParser from 'react-html-parser';

interface PropTypes {
  title: string;
  description: string;
  imageIllustration?: string
}

const OuNousTrouver = ({ title, description, imageIllustration }: PropTypes) => {
  const classes = styles();
  return (
    <Box className={classes.OuNousTrouverSection}>
      <Box className={classes.background}/>
      <Layout className={classes.OuNousTrouverContainer}>
        <Box className={classes.contentWrapper}>
          <Typography
            variant="h2"
            className={classnames(classes.title, 'titreSection')}
          >
            {title}
          </Typography>
          <Typography
            className={classnames('textParagraphe', classes.description)}
          >
            {ReactHtmlParser(description)}
          </Typography>
          <Box display='flex' justifyContent='center' className={classes.imageIllustrationWrapper}>
              <img src={imageIllustration} alt="img" />
          </Box>
        </Box>
      </Layout>
    </Box>
  );
};

export default OuNousTrouver;
