import React from 'react';
import styles from './style';
import Layout from '../../../Common/Layout';
import { Typography, Box } from '@material-ui/core';
import classnames from 'classnames';

import imgIllustration from '../../../../images/img2.svg';

import ReactHtmlParser from 'react-html-parser';
import ButtonScrollTo from '../../../Common/ButtonScrollTo';

interface PropTypes {
  title: string;
}

const paragraphes = [


  'HaiRun Technology est une Entreprise de Services du Numérique offshore, basée à Madagascar depuis plus de 5 ans, et implantée actuellement à Paris.',
  'Nous sommes spécialisés dans le développement des solutions digitales sur mesure , la maintenance d\'application web, mobile, logiciels spécifiques, plateformes en ligne. Nous proposons également des services dont la Data science, l\’Intelligence Artificielle et la Business Intelligence.',
  'Nous mettons à votre disposition, des ingénieurs ayant des niveaux de compétences sérieuses, en mode consultance. Dotés de solides softskills, nos consultants intégreront votre équipe pour assurer des missions à durée déterminée, ou à titre d’intérim, et vous accompagneront dans la réalisation de vos projets Data, ou ceux de vos partenaires.',
  'Au-delà de nos prestations, nous garantissons une innovation continue et une adaptation aux nouvelles technologies afin de satisfaire vos besoins en compétences. Nos formules sont modulables suivant l’évolution de vos projets, et notre équipe est imprégnée de la culture agile',
];

const AboutUs = ({ title }: PropTypes) => {
  const classes = styles();

  return (
    <Box bgcolor="#FFF" id="nos-services" className={classes.aboutUsSection}>
      <Box className={classes.background}></Box>
      <Box className={classes.aboutUs}>
        <Layout className={classes.aboutUsContainer}>
          <Box className={classes.aboutUsIllustrationWrapper}>
            <img src={imgIllustration} alt="hero" />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.aboutUsText}
          >
            <Typography
              variant="h2"
              className={classnames('titreSection', classes.aboutUsTitle)}
            >
              {ReactHtmlParser(title)}
            </Typography>

            {paragraphes.map((item, index) => (
              <Typography
                key={index}
                className={classnames(
                  'textParagraphe',
                  classes.aboutUsParagraphe,
                )}
              >
                {ReactHtmlParser(item)}
              </Typography>
            ))}

            <Box>
              <ButtonScrollTo
                element="#contact-form"
                text="Contactez-nous"
                color="#fff"
                backgroundColor="#E0363A"
              />
            </Box>
          </Box>
        </Layout>
      </Box>
    </Box>
  );
};

export default AboutUs;
