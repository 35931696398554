import React from 'react';

import { Button, Typography } from '@material-ui/core';
import { AnchorLink as Link } from 'gatsby-plugin-anchor-links';
import styles from './style';

interface PropsTypes {
  text: string;
  element: string;
  backgroundColor?: string;
  variant?: string;
  color?: string;
  borderColor?: string;
  endIcon?: any;
}
const ButtonScrollTo = ({
  element,
  text,
  backgroundColor,
  color,
}: PropsTypes) => {
  const classes = styles();
  return (
    <>
      <Button
        variant="outlined"
        style={{ backgroundColor: backgroundColor }}
        className={classes.button}
      >
        <Link to={element} className={classes.link}>
          <Typography
            variant="body2"
            component="span"
            className={classes.span}
            style={{ color: color }}
          >
            {text}
          </Typography>
        </Link>
      </Button>
    </>
  );
};

export default ButtonScrollTo;
