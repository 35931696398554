import React from 'react';

import {
  Typography,
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
} from '@material-ui/core';

import styles from './style';
import Layout from '../../../Common/Layout';

import ReactHtmlParser from 'react-html-parser';
import classnames from 'classnames';
import imgIllustration from '../../../../images/element_team.svg';
import IconArrowRightAlt from '../../../Common/Icons/IconArrowRightAlt';

import Ranto from '../../../../images/Ranto.svg';
import Chantal from '../../../../images/chantal.svg';
import Felix from '../../../../images/felix.svg';
import Tahiana from '../../../../images/tahiana.svg';
import Sophie from '../../../../images/sophie.svg';
import defaultTeamuser from '../../../../images/defaultTeamuser.svg';

interface PropTypes {
  title: string;
}

interface person {
  name: string;
  position: string;
  image?: string;
}

const team: person[] = [
  {
    name: 'Ranto Mamelonarivo ANDRIAMBOLOLONA',
    position: 'Président Directeur Général',
    image: Ranto,
  }, {
    name: 'Chantal RAZAFIMAHATRATRA',
    position: 'Directeur Général',
    image: Chantal,
  },
  {
    name: 'Félix RAKOTOMALALA',
    position: 'Directeur Administratif Financier',
    image: Felix,
  },
  {
    name: 'Sophie RABEARIMANANA',
    position: 'Coordinateur général',
    image: Sophie,
  },
  {
    name: 'Tahiana RAZAFIMAHATRATRA',
    position: 'Directeur Technique',
    image: Tahiana,
  },
];

const Team = ({ title }: PropTypes) => {
  const classes = styles();
  const url = '/recrutement';

  return (
    <Box className={classes.teamSection}>
      <Layout className={classes.teamContainer}>
        <Box display="flex" alignItems="flex-start" className={classes.topBox}>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.teamText}
          >
            <Typography
              variant="h2"
              className={classnames('titreSection', classes.teamTitle)}
            >
              {ReactHtmlParser(title)}
            </Typography>

            <Typography
              className={classnames('textParagraphe', classes.teamParagraphe)}
            >
              Notre but est de vous accompagner dans la mise en place de vos
              projets web informatiques, concordant avec vos objectifs et vos
              cibles.
            </Typography>
            <Button
              href={url}
              className={classnames('classicButton', classes.rejoignezNous)}
              variant="outlined"
              endIcon={<IconArrowRightAlt viewBox="0 0 24 24" />}
            >
              Contactez-nous
            </Button>
          </Box>
          <Box className={classes.teamIllustrationWrapper}>
            <img src={imgIllustration} alt="img" />
          </Box>
        </Box>
      
        <Box display="flex" alignItems="center" className={classes.listingTeam}>
          {team.map(({ name, position, image }, index) => (

            <Card className={classes.card} key={index}>
              <CardMedia
                className={classnames(classes.media)}
                image={image ? image : defaultTeamuser}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  component="h5"
                  className={classes.name}
                >
                  {name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classnames('textParagraphe', classes.position)}
                >
                  {position}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Layout>
    </Box>
  );
};

export default Team;