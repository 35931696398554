import React from 'react';
import AboutUs from './AboutUs';
import Team from './Team';
import OuNousTrouver from './OuNousTrouver';
import map from '../../../images/map.svg';
import ContactForm from '../../Common/ContactForm';

function APropos() {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");
  return (
    <>
      <AboutUs title="A propos <span>de</span><br/> nous" />
      <Team title="La team <br />par excellence" />
      <OuNousTrouver
        title="Où nous trouvez"
        description="HaiRun Technology est une entreprise au service du numérique (ESN) sise à <span>Madagascar</span> et à <span>Paris</span>."
        imageIllustration={map}
      />
      {!domaine_ext ?
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.com"
          formTitle="Contactez-<span>nous</span>"
        />
        :
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.fr"
          formTitle="Contactez-<span>nous</span>"
        />}
    </>
  );
}

export default APropos;
